.h90 {
  height: 90%;

  @media only screen and (max-width: 769px) {
    height: 100%;
  }
}

.container {
  width: 270px;
  // overflow-y: auto;

  @media only screen and (max-width: 769px) {
    width: 100%;
    border-right: none;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: white, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 30px;
  color: #29303e;

  @media only screen and (max-width: 769px) {
    font-size: 1rem;
  }
}
