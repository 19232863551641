.textarea {
  min-width: unset !important;
}

.container {
  display: block;
  height: unset;
}

.popOver {
  z-index: 10000000;

  :global {
    .box {
      border: 1px solid #dbdbdb;
    }
  }
}

.image {
  img {
    max-width: 264px;
    max-height: 264px;
    width: 128px;
    height: 128px;
    object-fit: cover;
    object-position: center;
  }
}

.video {
  width: 100%;
}

.input {
  max-height: unset !important;
  height: unset !important;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;

  >* {
    width: 100%;
    margin-top: 2px;
  }
}

.popoverBtn {
  &:focus {
    box-shadow: none !important;
  }
}