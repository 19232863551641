.swatch {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.popover {
  position: absolute;
  bottom: 0;
  z-index: 2000000;
}

.popover2 {
  position: absolute;
  // bottom: 0;
  z-index: 1;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
