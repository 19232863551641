$card-radius: 0.5rem;
$family-sans-serif: "Poppins";
// $size-1: 2rem;
// $size-2: 1.75rem;
// $size-3: 1.5rem;
// $size-4: 1.25rem;
// $size-5: 1rem;
// $size-6: 0.75rem;
// $size-7: 0.625rem;
$body-size: 14px;
$body-colo: #414974;
$primary: #2a79ef;
$secondary: #e0e5ff;
$link: #2a79ef;
$info: #0088ad;
$success: #27ae60;
$warning: #996e00;
$danger: #f55755;
$light: #fafbfd;

$input-height: 3.5rem;
$border: rgba(222, 225, 230, 0.4);

$badge-height: 10px;

$dimensions: 16 24 32 40 48 64 96 128 !default;
