:root {
  --help-center-primary-color: #2a79ef;
  --help-center-secondary-color: #98c2f3;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

// ::-webkit-scrollbar-thumb {
// 	-webkit-border-radius: 10px;
// 	border-radius: 0px;
// 	background: var(--help-center-primary-color) !important;
// }

.help-center-primary-bg {
  background: linear-gradient(
    180deg,
    var(--help-center-primary-color) 0%,
    rgba(42, 121, 239, 0) 100%
  );
}

$color_1: inherit;
$font-family_1: monospace, monospace;
$font-family_2: inherit;
$background-color_1: transparent;

.remove-default-styling {
  height: 100% !important;

  .ql-container {
    height: 100% !important;
  }

  .ql-editor {
    padding: 0;
    overflow: hidden;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  pre {
    font-family: $font-family_1;
    font-size: 1em;
  }
  a {
    background-color: $background-color_1;
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }
  b {
    font-weight: bolder;
  }
  strong {
    font-weight: bolder;
  }
  code {
    font-family: $font-family_1;
    font-size: 1em;
  }
  kbd {
    font-family: $font-family_1;
    font-size: 1em;
  }
  samp {
    font-family: $font-family_1;
    font-size: 1em;
  }
  small {
    font-size: 80%;
  }
  sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    bottom: -0.25em;
  }
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
  }
  img {
    border-style: none;
  }
  button {
    font-family: $font-family_2;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    &::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }
    &:-moz-focusring {
      outline: 1px dotted ButtonText;
    }
  }
  input {
    font-family: $font-family_2;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
  }
  optgroup {
    font-family: $font-family_2;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  select {
    font-family: $font-family_2;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    text-transform: none;
  }
  textarea {
    font-family: $font-family_2;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: auto;
  }
  [type="button"] {
    -webkit-appearance: button;
    &::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }
    &:-moz-focusring {
      outline: 1px dotted ButtonText;
    }
  }
  [type="reset"] {
    -webkit-appearance: button;
    &::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }
    &:-moz-focusring {
      outline: 1px dotted ButtonText;
    }
  }
  [type="submit"] {
    -webkit-appearance: button;
    &::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }
    &:-moz-focusring {
      outline: 1px dotted ButtonText;
    }
  }
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  legend {
    box-sizing: border-box;
    color: $color_1;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  [type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }
  [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  [type="number"] {
    &::-webkit-inner-spin-button {
      height: auto;
    }
    &::-webkit-outer-spin-button {
      height: auto;
    }
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
  &::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  details {
    display: block;
  }
  summary {
    display: list-item;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none;
  }
}

.preview_wrap {
  // width: 100%;
  // height: 800px;
  // padding: 0;
  // overflow: hidden;
}
.frame_wrap {
  width: 1000px;
  height: 786px;
  border: 0;
  -ms-transform: scale(0.75);
  -moz-transform: scale(0.75);
  -o-transform: scale(0.75);
  -webkit-transform: scale(0.75);
  transform: scale(0.75);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.hover-underlined {
  text-decoration: none;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
}
