.buttons {
  width: 40px;
  height: 40px;

  &:not(:last-child) {
    margin-right: 6px;
  }
}

.fieldsContainer {
  max-width: 316px;
}
