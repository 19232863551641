.disabled {
    pointer-events: none;
    opacity: 0.8;
    cursor: no-drop;
}

.status {
    cursor: pointer;
}

.status:hover {
    background: #ebeef3d9;
}

.statusIsActive {
    background: #ebeef3d9;
}