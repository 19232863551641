.whatsapp-app {
    height: 600px !important;
    // width: 32.5%;
    position: sticky;
    top: 20px;
  
    .iphone {
      align-self: center;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background: url('https://cloud.githubusercontent.com/assets/8906380/15149095/a2160bc2-169e-11e6-902a-222a91f2f03a.jpg') no-repeat center;
      background-size: cover;
      color: black;
      text-align: left;
  
      .bar {
        &--ios {
          flex-shrink: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 1.2rem;
          height: 48px;
          background: #F7F7F7;
        }
  
        &--conversation {
          padding: 8px 16px;
          overflow: scroll;
          flex-grow: 1;
        }
  
        &--sender {
          padding: 10px;
          height: 26px;
          // border-top: 1px solid $color-dark-gray;
        }
      }
    }
  
    .message {
      padding: 6px 10px;
      border-radius: 8px;
      float: right;
      clear: both;
      max-width: 76%;
      box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  
      &--else {
        background-color: white;
        float: left;
      }
  
      &+& {
        margin-top: 2px;
      }
    }
  }