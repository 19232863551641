.h100 {
  height: 100%;
  min-width: 6.4rem;
  max-width: 6.4rem;
  z-index: 100;
}

.activeMenu {
  background-color: #dee2ec45;
  padding: 0.4rem;
  border-radius: 0.3rem;
}

.channelWrapper {
  box-shadow: 0px 4px 10px rgba(193, 192, 192, 0.25);
  border-radius: 10px;
  width: 170px;
  border: 1px solid #f0f0f0;
  background: #ffffff;
  overflow: hidden;
}

.channelName {
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.8rem;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #404040;
}

.active {
  height: 2.5rem;
  width: 5.5rem;
  background: #ffffff;
  position: absolute;
  top: 2px;
  border-radius: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  filter: drop-shadow(0px 0px 4px rgba(88, 88, 88, 0.15));
  left: -13px;
}
