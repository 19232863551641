.header {
    height: 5.56rem;
    min-height: 48px;
    max-height: 5.56rem;

    @media only screen and (max-width: 769px) {
        height: 4rem;
    }
}

.title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 30px;
    color: #29303E;

    @media only screen and (max-width: 769px) {
        font-size: 1rem;
    }
}