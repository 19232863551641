.container {
  position: relative;
  // padding-left: 1rem !important;
  // padding-right: 1rem !important;

  @media only screen and (max-width: 769px) {
    width: 100%;
    border-right: none;
    // padding-left: 0.5rem !important;
    // padding-right: 0.5rem !important;
  }
}

.threadContainer {
  overflow-y: auto;
  width: 400px;

  @media only screen and (max-width: 769px) {
    padding-top: 0px;
    width: 100%;
  }
}

.header {
  gap: 5px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 0.5rem 1rem;
  align-items: center;
}

.scrollContainer {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.callScrollContainer {
  height: 200px !important;
  background-color: rgba(0, 0, 0, 0.4);

  .gradient {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    height: 200px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 50%
    );
    pointer-events: none;
  }
}

.starIcon {
  stroke: aquamarine;
  fill: goldenrod;
}

.noBtn {
  border: none;
  background: none;
  cursor: pointer;
}

.videoBtn {
  background: white;
  border-radius: 50%;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: white, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
