@charset "utf-8";

@import "./variables.scss";
@import "bulma";
@import "./libs";

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

:root {
  --root-position: absolute;
}

#root {
  position: var(--root-position);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  overflow: hidden;
}

video {
  background-color: black;
}

.has-rounded-corners {
  border-radius: $card-radius;
  overflow: hidden;
}

.has-flat-corners {
  border-radius: 0;
  overflow: hidden;
}

.has-border {
  border: 1px solid $border !important;
}

.has-border-dark {
  border: 1px solid #949494 !important;
}

.has-border-dark:has(.is-expanded-change-border) {
  border: 1px solid $primary !important;
}

.has-border-left {
  border-left: 1px solid $border;
}

.has-border-right {
  border-right: 1px solid $border;
}

.has-border-top {
  border-top: 1px solid $border;
}

.has-border-top-white {
  border-top: 1px solid transparentize($color: white, $amount: 0.8);
}

.has-border-bottom {
  border-bottom: 1px solid $border;
}

.has-border-bottom-white {
  border-bottom: 1px solid transparentize($color: white, $amount: 0.8);
}

.is-list-item {
  @extend .has-rounded-corners;
  @extend .mb-1;

  &:hover {
    background-color: #ebeef3d9;
  }

  &.is-active {
    background-color: #ebeef3d9;
  }

  &:last-child {
    @extend .mb-0;
  }
}

.is-overflow-y-auto {
  overflow-y: auto;
}

.has-long-text {
  line-break: anywhere;
  white-space: pre-wrap;
}

.has-ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.columns,
.is-flex {
  min-width: 0;
  min-height: 0;
}

.has-background-primary-with-text {
  @extend .has-background-primary;
  @extend .has-text-white;
}

.bubble-light {
  @extend .has-rounded-corners;
  @extend .has-background-primary-light;
}

.bubble-left {
  @extend .p-3;
  // @extend .has-background-secondary;

  border-radius: 1rem;
  border-bottom-left-radius: 0;

  background-color: #fff;
  color: #414974;
  border: 1px solid #d9d9d9;
}

.bubble-right {
  @extend .p-3;
  @extend .has-background-primary-with-text;

  a {
    color: white;
  }

  a:hover {
    color: #363636;
  }

  border-radius: 1rem;
  border-bottom-right-radius: 0;
}

strong {
  color: inherit;
}

.is-vertical-align-middle {
  vertical-align: middle;
}

.full-screen-mobile {
  &:not(.is-active) {
    @media only screen and (max-width: $tablet) {
      display: none !important;
    }
  }
}

.is-primary {
  svg {
    color: $primary;
  }
}

.tribute-container {
  @extend .box;
  border: 1px solid #dbdbdb;
  padding: 0;

  ul {
    background: #fff;

    > li {
      @extend .px-3;

      &:not(:last-child) {
        border-bottom: 1px solid $border;
      }
    }

    .highlight {
      background: $light;
      margin-bottom: 0 !important;
    }
  }
}

.modal {
  z-index: 9999999;

  .modal-card {
    border-radius: 14.6528px;
  }

  .modal-background {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .modal-card-body {
    padding: 10px 26px;
  }

  @media screen and (min-width: 769px) {
    .model-content,
    .modal-card {
      width: 442px;
    }
  }

  @media screen and (max-width: 769px) {
    .model-content,
    .modal-card {
      width: 88%;
    }
  }

  .modal-card-head,
  .modal-card-foot,
  .modal-card {
    background-color: #fff;
  }

  .modal-card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #282828;
  }

  .modal-card-head {
    padding: 0 26px;
    border: none;
  }

  .modal-card-foot {
    border: none;
    padding: 0 26px 26px 26px;
  }

  &.is-fullscreen {
    .modal-card {
      max-height: unset;
      height: 100%;
      width: 100%;
    }

    .modal-card,
    .modal-card-head,
    .modal-card-foot {
      border-radius: 0px;
    }
  }

  &.is-mediumscreen {
    .modal-card {
      height: 60%;
      width: 65%;
    }
  }

  &.is-largescreen {
    .modal-card {
      height: 75%;
      width: 70%;
    }
  }

  &.is-large-xl-screen {
    .modal-card {
      height: 90%;
      width: 85%;
    }
  }

  &.is-widescreen-h {
    .modal-card {
      height: 100%;
      width: 50%;
    }
  }

  &.is-fullscreen-mobile {
    .modal-card {
      @media only screen and (max-width: 769px) {
        max-height: unset;
        height: 100%;
        width: 100%;
      }
    }
  }

  &.no-scrollable {
    .modal-card-body {
      display: flex;
      flex-direction: column;
    }
  }

  &.allow-card-overflow {
    .modal-card {
      overflow: visible !important;
    }
  }
}

.navbar-item {
  &:focus,
  &:hover {
    background-color: inherit !important;
  }

  &.is-clickable:not(.is-active) {
    &:focus,
    &:hover {
      background-color: #ffffff15 !important;
    }
  }

  &.is-active {
    background-color: #ffffff32 !important;

    &:hover {
      background-color: #ffffff32 !important;
    }
  }
}

.is-scrollable-y {
  overflow-y: auto;
  height: 100%;
}

.is-scrollable-x {
  overflow-x: auto;
  width: 100%;
}

.image {
  img {
    object-position: center;
    object-fit: cover;
    max-height: 100%;
  }
}

.is-flex-1 {
  flex: 1;
}

.tag.is-clickable {
  @media only screen and (max-width: $tablet) {
    font-size: 0.865rem;
  }
}

.react-time-picker__wrapper {
  @extend .input;
  border: 1px solid $border !important;

  &:hover {
    @extend .is-hovered;
  }

  .react-time-picker__inputGroup {
    min-width: 48px;
  }

  .react-time-picker__clear-button {
    @extend .pr-0;
  }
}

.rhap_container {
  box-shadow: none;
  padding-bottom: 5px;
}

.rhap_additional-controls {
  display: none;
}

.rhap_container {
  border-radius: $card-radius;
}

.ReactVirtualized__Grid {
  outline: none !important;
}

@keyframes success-danger-color-change {
  0% {
    color: $success;
    stroke: currentColor;
  }

  50% {
    color: $danger;
    stroke: currentColor;
  }

  100% {
    color: $success;
    stroke: currentColor;
  }
}

.has-text-animation-success-danger {
  animation: success-danger-color-change 2s infinite;
}

.avatar {
  width: 56px;
  height: 56px;
  background-color: darken($light, 5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch[type="checkbox"] + label {
  padding-top: 0;
}

.card {
  &:not(:last-child) {
    @extend .mb-2;
  }
}

.is-visible-hover-parent {
  .is-visible-hover-child {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  &:hover {
    .is-visible-hover-child {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }
}

.table-container {
  overflow-y: auto;
}

.pagination-link {
  cursor: pointer;
}

.pagination-previous.is-disabled,
.pagination-next.is-disabled,
.pagination-link.is-disabled {
  pointer-events: none;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
  box-shadow: none;
  color: #7a7a7a;
  opacity: 0.5;
}

.PhoneInputInput {
  border: none;
}

.has-child-full-height {
  > * {
    height: 100%;
  }
}

.stripe-input {
  > div {
    width: 100%;
  }
}

.title {
  font-weight: 500;
  color: #303030;
}

.label {
  color: #303030;
  display: block;
  font-size: 1rem;
  font-weight: 500;
}

.custom-input {
  height: $input-height;
  background: #ffffff;
  border: 2.23776px solid #dbdee6;
  border-radius: 6px;

  &::-webkit-input-placeholder {
    color: #868686;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    background: #f7f7fd;
    border: 1px solid #e0def7;
    border-radius: 10px;
    font-size: 0.9375rem;
    padding: 0 1rem;

    &::-webkit-input-placeholder {
      color: #7b7f92;
      font-weight: 500;
      // padding-left: 0.5rem;
    }
  }
}

.custom-select {
  width: 100%;

  select {
    width: 100%;
    height: $input-height;
    background: #ffffff;
    border: 2.23776px solid #dbdee6;
    border-radius: 6px;

    &::-webkit-input-placeholder {
      color: #868686;
      font-weight: 500;
    }
  }
}

.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input {
    box-sizing: border-box;

    width: 18px;
    height: 18px;

    border: 1.4px solid #d9d9d9;
    border-radius: 4px;
  }

  span {
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    color: #868686;
  }
}

.custom-button {
  height: 3.5rem;
}

.icon {
  &.has-text-danger {
    stroke: currentColor;
  }

  &.has-text-success {
    stroke: currentColor;
  }
}

.no-focus {
  &:focus {
    border: none;
    box-shadow: none !important;
  }
}

.label-createable {
  .field {
    margin: 0 !important;
  }

  .select {
    height: 3rem !important;
    border: 1px solid #ededed;
    border-radius: 10px;
  }

  .icon {
    height: 3rem !important;
  }
}

.w-100 {
  width: 100%;
}

.media + .media {
  border-top: 0;
  margin-top: 0;
}

.navbar-link:not(.is-arrowless)::after,
.select:not(.is-multiple):not(.is-loading)::after {
  margin-top: 0;
  transform: translateY(-50%) rotate(-45deg);
}

.select:not(.is-multiple) {
  height: inherit;
}

.table thead td,
.table thead th {
  border-width: inherit;
  color: inherit;
}

.PhoneInputInput {
  &:focus-visible {
    outline: none;
  }

  @media (max-width: 768px) {
    background: #f7f7fd;

    &::-webkit-input-placeholder {
      color: #7b7f92;
      font-weight: 500;
      // padding-left: 0.5rem;
    }
  }
}

.custom-tabs {
  ul {
    border: none;
    gap: 1rem;
  }

  li {
    min-width: 110px;
  }

  li a {
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    background: $light;
    color: $primary;
    border: none;

    &:hover {
      border: none;
      color: $primary;
      box-shadow: none;
    }
  }

  li.is-active a {
    border: none;
    background: $primary;
    color: #fff;
  }
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #d4dce7;
  border-radius: 0.15em;
  display: inline-grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /*box-shadow: inset 1em 1em #959495;*/
  background-color: $primary;
}

input[type="checkbox"]:checked {
  border: 0.15em solid $primary;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.is-no-drop {
  cursor: no-drop;
}

.is-no-box-shadow {
  &:focus {
    box-shadow: none !important;
  }
}

.steps .step-item .step-marker {
  height: 2.5rem;
  width: 2.5rem;
}

.steps {
  .step-item {
    .step-marker {
      background: #f0f1fa;
      color: #8f97a2;
    }

    .step-details .step-title {
      margin-top: 0.5rem;
      font-weight: 500;
      font-size: 1rem;
      line-height: 24px;
      text-align: center;
      color: #181b42;

      @media (max-width: 769px) {
        font-size: 0.7rem;
      }
    }

    &:not(:first-child) {
      &::before {
        height: 0.13em;
        top: 1.25rem;
      }
    }

    &::before {
      background: #ebecf3;
    }
  }
}

.h-3-5 {
  height: 3.5rem;
}

.react-datepicker-popper {
  z-index: 1000000000;
}

.react-datepicker {
  box-shadow: 0px 3.6758px 14.7032px rgba(0, 0, 0, 0.05);
  border-radius: 11.7626px;
  border: none;
  margin-top: 1rem;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    border: none;
    background: none;
    padding: 0;
    margin-top: 1rem;
  }

  .react-datepicker__current-month {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 12px;
    letter-spacing: -0.113215px;
    color: #0f2552;
  }

  .react-datepicker__navigation--previous {
    left: 3rem;
    border-right-color: #848a95;
  }

  .react-datepicker__navigation--next {
    right: 3rem;
    border-left-color: #848a95;
  }

  .react-datepicker__navigation {
    top: 15px;
  }

  .react-datepicker__day-name {
    font-weight: 600;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-transform: uppercase;
    color: #676767;
  }

  .react-datepicker__day-names {
    border-top: 0.588128px solid #e4e5e7;
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .react-datepicker__day {
    font-weight: 600;
    color: #1b59f8;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: #c8dbff;
    border-radius: 40px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: #0062ff;
    border-radius: 40px;
    color: white;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background: #c8dbff7d;
    border-radius: 40px;
  }
}

.custom-box {
  background: #fefeff;
  box-shadow: 0px 5px 20px rgba(249, 249, 249, 0.8);
  border-radius: 10px;
}

@media (max-width: 640px) {
  .sm-hidden {
    display: none !important;
  }
}

.w-100-responsive {
  width: 100%;
  overflow: hidden;

  @media (max-width: 640px) {
    margin-top: 4.5rem;
    margin-bottom: 3.6rem;
  }
}

.main-container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90%;

  @media (max-width: 640px) {
    height: 87%;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}
:root {
  --thumb-color: #c3cdfd;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 0px;
  background: var(--thumb-color);
}

.field-no-margin {
  .field {
    margin-bottom: 0;
  }
}

.table-header-transparent {
  thead > tr > th {
    background-color: transparent;
  }
}

.has-custom-icon {
  .control.has-icons-left .icon,
  .control.has-icons-right .icon {
    height: -webkit-fill-available;
    width: 3rem;
  }
}

:root {
  --main-color: rgb(104, 117, 217);
  --main-color-hover: rgb(104, 117, 217, 0.2);
  --track-color: #ddd;
  --mark-color: #aaaaaa;
}

.customSlider {
  // max-width: 300px;
  // margin: auto;
  height: 20px;
}

.customSlider-track {
  top: 8px;
  height: 4px;
  background: var(--track-color);
}

.customSlider-track.customSlider-track-0 {
  background: $primary;
}

.customSlider-thumb {
  // cursor: pointer;
  background: $primary;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  outline: none;
}

.customSlider-thumb:hover {
  box-shadow: 0 0 0 8px var(--main-color-hover);
}

.customSlider-mark {
  cursor: pointer;
  top: 6px;
  width: 1.5px;
  height: 8px;
  background-color: var(--mark-color);
}

.customSlider-mark.customSlider-mark-before {
  background-color: $primary;
}

.customSlider-mark.customSlider-mark-active {
  display: none;
}

.is-loading {
  pointer-events: none;
}

.is-no-flex-sm {
  @media only screen and (max-width: 769px) {
    flex: none;
    display: inherit !important;
  }
}

.is-sm-w-full {
  @media only screen and (max-width: 769px) {
    width: 100% !important;
  }
}

.quill {
  height: 300px;

  &.is-disabled {
    background-color: whitesmoke;
    border-color: whitesmoke;
    box-shadow: none;
    color: #7a7a7a;
    cursor: not-allowed;
    pointer-events: none;
  }

  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border-color: $border;
    border-radius: 4px;
  }

  &.is-danger {
    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-radius: 4px;
      border: 1px solid $danger;
    }

    .ql-container.ql-snow {
      border-top: 0px;
    }
  }

  .ql-container {
    height: 87%;
  }
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ant-table-column-title {
  color: #838486 !important;
}

.ant-table-cell {
  font-size: 16px;
  color: #838486;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

.no-styling-textarea {
  border: none;
  overflow: auto;
  outline: none;

  width: 100%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  /*remove the resize handle on the bottom right*/
}

.custom-pre {
  background-color: transparent;
  color: #4a4a4a;
  font-size: 0.9em;
  overflow-x: auto;
  white-space: break-spaces;
  word-wrap: normal;
  font-family: "Poppins";
  padding: 0;
}

.is-image-rounded {
  border-radius: 10000px !important;
}

.ant-select-dropdown {
  z-index: 1000000000;
}

.icon-2-5 {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.has-no-max {
  max-width: unset !important;
}

.flow {
  .s-box {
    height: 112px;
    width: 224px;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08),
      0px 2px 12px rgba(0, 0, 0, 0.06);
  }
  .s-box-selected {
    outline: 2px solid #c3cdfd;
  }

  .react-flow-step__handle-left {
    background: none !important;
    border: none;
  }

  .react-flow-step__handle-right {
    width: 24px;
    height: 24px;
    background: #1a192b;
    border: none;
    border-radius: 100%;
    color: white;
    text-align: center;
    padding: 2px;
    font-size: 16px;
    right: -12px;
  }
}

.ant-select-custom-sm {
  .ant-select-selection-search-input {
    font-size: 0.7rem !important;
  }
  .ant-select-selector {
    font-size: 0.7rem !important;
  }
}

.ant-select-custom-popup {
  .ant-select-item {
    min-height: 28px;
  }
  .ant-select-item-option-content {
    font-size: 0.8rem !important;
  }
}

.loaderClass {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: white, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-calendar-datepicker {
  box-shadow: none;
  border-radius: 0;
  border: none;
  margin-top: 1rem;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    border: none;
    background: none;
    padding: 0;
    margin-top: 1rem;
  }

  .react-datepicker__current-month {
    font-weight: 500;
    font-size: 1rem;
    line-height: 12px;
    letter-spacing: -0.113215px;
    color: #0f2552;
  }

  .react-datepicker__navigation--previous {
    left: 3rem;
    border-right-color: #848a95;
  }

  .react-datepicker__navigation--next {
    right: 3rem;
    border-left-color: #848a95;
  }

  .react-datepicker__navigation {
    top: 15px;
  }

  .react-datepicker__day-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
    text-align: center;
    text-transform: uppercase;
    color: #353535;
    width: 2.5rem;
    line-height: 2.5rem;
    margin: 0.5rem;
  }

  .react-datepicker__day-names {
    border-top: none;
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .react-datepicker__day {
    font-weight: 400;
    color: #353535;
    width: 2.5rem;
    line-height: 2.5rem;
    margin: 0.5rem;
    font-size: 14px;
  }

  .react-datepicker__day:not(.react-datepicker__day--disabled) {
    font-weight: 600;
    color: #0362ff;
    background: #f0f5ff;
    border-radius: 50%;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    color: #353535;
    background: #fff;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: #0062ff !important;
    color: white !important;
  }

  .react-datepicker__day.react-datepicker__day--disabled:hover {
    background: #fff;
    color: #353535;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background: #dae6ff;
    color: #0062ff;
    border-radius: 40px;
  }
}

.badge {
  &.no-box-shadow {
    box-shadow: none !important;
  }
}

.has-background-secondary {
  background: $secondary;
}

.pin-message-button {
  .icon-unpinned {
    display: none; // Initially hide the unpinned icon
  }

  &:hover {
    .icon-pinned {
      display: none; // Hide the pinned icon on hover
    }

    .icon-unpinned {
      display: inline-block; // Show the unpinned icon on hover
    }
  }
}

.quick-reply-popover {
  .ant-collapse-header {
    padding: 8px !important;
    padding-inline-start: 7px !important;
    border-bottom: 1px solid rgba(222, 225, 230, 0.4);
  }
  .ant-collapse-content-box {
    padding: 0px !important;
  }
}
