.botSelected {
  background: #dfe4ff;
  color: #2a79ef;
  pointer-events: none !important;

  &:hover {
    color: #2a79ef;
    background: #dfe4ff;
  }
}

.botItem {
  display: block;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  position: relative;
}

.addBotIcon {
  border-radius: 4px;
  background: #e7e6ff;
  color: #818c98;
  font-size: 0.8rem;
}
