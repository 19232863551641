.tags-input-v2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 48px;
    // width: 480px;
    padding: 0 8px;
    border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;
    background: #fff;

    &:focus-within {
        border: 1px solid $secondary;
    }

    input {
        flex: 1;
        border: none;
        height: 46px;
        font-size: 14px;
        padding: 4px 0 0 0;

        &:focus {
            outline: none;
            border: none;
            box-shadow: none;
        }
    }
}

#tags-v2 {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    gap: 0.5rem;
    margin-right: 0.7rem;
    // margin: 8px 0 0 0;
}

.tag-v2 {
    width: auto;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    // color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 8px;
    background: whitesmoke;

    .tag-title {
        margin-top: 3px;
    }

    .delete {
        margin-left: 0.25rem;
        margin-right: -0.25rem;
        margin-top: 2px;
    }

    .tag-close-icon {
        display: block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        margin-left: 8px;
        color: #0052cc;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
    }
}

// @media screen and (max-width: 567px) {
//     .tags-input-v2 {
//         width: calc(100vw - 32px);
//     }
// }