.messageContent {
  line-height: 1;
}

.userName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
}

.date {
  white-space: nowrap;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 18px;
  color: #7e8b9d;
}

.starred {
  :global {
    .image > .is-rounded {
      border: 2px solid goldenrod;
    }
  }
}

.starIcon {
  color: goldenrod;
}

.tagSuccess {
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 15px;
  text-align: right;
  color: #ffffff;
  background: #27ae60 !important;
}

.channelWrapper {
  display: flex;
  align-items: center;
}

.channel {
  margin-left: 0.3rem;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #9e9e9e;
}

.isDanger {
  background: #f55755;
}

.userGroupIconColor {
  stroke: #6f7b8b;
}

.requestWrapper {
  background: white;
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  box-shadow: 5px 5px 5px 5px rgba(243, 243, 243, 0.8);
}

.requestBtn {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 18px;
  color: #ffffff;
  border-radius: 46px !important;
  padding: 0.2rem 0.875rem !important;
  height: 1.6rem;

  img {
    padding-right: 0.4rem;
  }
}

.requestRejectIcon {
  transform: rotate(130deg);
}

.boardItem {
  box-shadow: 0px 0px 10px #eef1f7;
}

.status {
  border-radius: 50%;
  background: rgb(39, 174, 96);
  height: 0.8rem;
  width: 0.8rem;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid white;
}
