.container {
  // overflow-y: auto;
  width: 350px;

  @media only screen and (max-width: 769px) {
    padding-top: 0px;
    width: 100%;
  }

  article {
    padding-bottom: 1rem;
  }
}

.header {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}

.actionBtn {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #868686;
}

.selectTab {
  border-radius: 6px;
  padding: 4px 10px;
  margin: 0 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: #7e808a;
  cursor: pointer;
}

.selectTabActive {
  background: #dfe4ff;
  color: #2a79ef;
}

.labelButton {
  background: #ffffff;
  border: none;
  box-shadow: 0px 2px 5px rgba(243, 243, 243, 0.8);
  border-radius: 56px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #585a5e;
  padding: 18px 25px;

  &:focus {
    border: 0;
    box-shadow: 0px 2px 5px rgba(243, 243, 243, 0.8) !important;
  }
}

.labelWrapper {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(193, 192, 192, 0.25);
  border-radius: 10px;
  padding: 1rem;
}

.labelInput {
  width: 4rem;
  border: none;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  /* identical to box height */

  color: #aaaaaa;

  &:focus {
    border: none;
    outline: none;
  }
}

.labelDiv {
  // max-width: 16rem;
  // height: 40px;
  background: #fbfbfb;
  border-radius: 23px;
  margin: 0.4rem 0;
  // display: flex;
  // align-content: space-evenly;
  // flex-wrap: wrap;
  // justify-content: space-between;
  padding: 0 0.2rem 0px 1rem;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #4e5d7b;
}

.labelCreateable {
  min-width: 16rem;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #747474;
}

.notesInput {
  border: 1px solid #ededed;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #747474;
  height: 3.5rem;
  padding-left: 1rem;

  &::-webkit-input-placeholder {
    color: #7b7f92;
  }
}
