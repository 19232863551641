.userName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
}
