.container {
  gap: 1.2rem;
  min-height: 52px;

  @media only screen and (max-width: 769px) {
    gap: 0.5rem;
  }
}

.messageBox {
  border-radius: 0.5rem;
}

.textarea {
  min-width: unset !important;

  background: #ffffff;
  border: none;
  // border: 1px solid #F8F8F8;
  // border-radius: 76px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding-left: 1rem;
  padding-right: 1rem;

  // @media only screen and (max-width: 769px) {
  //   padding-left: 1rem;
  //   padding-right: 5rem;
  // }

  &:focus {
    // border: 1px solid #F8F8F8;
    box-shadow: none;
  }

  &:hover {
    // border: 1px solid #F8F8F8;
  }

  &::-webkit-input-placeholder {
    color: #8c9ae6;
  }
}

.container {
  display: block;
  height: unset;
}

.popOver {
  z-index: 1000000000;

  :global {
    .box {
      border: 1px solid #dbdbdb;
    }
  }
}

.image {
  img {
    max-width: 264px;
    max-height: 264px;
    width: 128px;
    height: 128px;
    object-fit: cover;
    object-position: center;
  }
}

.video {
  width: 100%;
}

.imageCR {
  img {
    max-width: 128px;
    max-height: 128px;
    width: 64px;
    height: 64px;
    object-fit: cover;
    object-position: center;
  }
}

.videoCR {
  width: 100%;
  max-height: 100px;
}

.input {
  max-height: unset !important;
  height: unset !important;
  align-items: flex-start;
}

.column {
  display: flex;
  // flex-direction: column;
  width: 100%;

  > * {
    width: 100%;
    margin-top: 2px;
  }
}

.relative {
  position: relative;
}

.sendBtn {
  position: absolute;
  right: 1rem;
  top: 5px;

  @media only screen and (max-width: 769px) {
    right: 0.1rem;
  }
}

.attachmentContainer {
  gap: 2rem;

  @media only screen and (max-width: 769px) {
    gap: 0.5rem;

    img {
      width: 2.5rem;
    }
  }
}
.mentionContainer {
  // max-height: 250px;
  // overflow: scroll;
}
