.container {
  min-width: 280px;
  max-width: 500px;

  @media only screen and (max-width: 769px) {
    flex-grow: 1;
    min-width: unset;
    max-width: 287px;
  }
}

.messageStatus {
  opacity: 0.7;
}

.deletedMessage {
  opacity: 0.6;
}

.referral {
  background-color: rgba($color: #000000, $alpha: 0.05);
  border-radius: 8px;

  >p {
    max-width: 300px;
    font-size: smaller;
  }
}

.timeColor {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 21px;
  color: #B9BCCE;
}

.font13 {
  font-size: 0.94rem;
}

.disconnectIcon {
  transform: rotate(130deg);
}

.isRoudedLeft {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
  padding-left: calc(1em + 0.25em) !important;
}

.isRoudedRight {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
  padding-right: calc(1em + 0.25em) !important;
}