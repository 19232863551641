.container {
  z-index: 2;
  overflow-x: hidden;
  min-width: 56px;
  max-width: 56px;
  background: #ffffff;

  color: #4e5d7b;
}

.header {
  height: 5.56rem;
  min-height: 48px;
  max-height: 5.56rem;

  .logo {
    max-width: 8.43rem;
  }
}

.sidebarBg {
  background: #fafbfd;
  overflow-y: scroll;
  height: 90%;
}

.sidebarItem {
  height: 3rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 21px;
  color: #4e5d7b;
}

.sidebarMenu {
  height: 3rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 21px;
  color: #4e5d7b;

  &:hover {
    color: #2a79ef;
  }
}

.sidebarMenuActive {
  background: white !important;

  &:focus {
    background-color: white !important;
    background: white !important;
  }

  &:hover {
    background-color: white !important;
    background: white !important;
  }
}

.opened {
  max-width: 18.125rem;
  min-width: 18.125rem;

  @media only screen and (max-width: 769px) {
    width: 100% !important;
    max-width: unset;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.icon {
  min-width: 24px;
}

.itemClosed {
  width: 42px;
}

.addBotIcon {
  border-radius: 4px;
  background: #e7e6ff;
  color: #818c98;
  font-size: 0.8rem;
}

.mobileContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .mobileLogo {
    margin-left: 4px;
    max-width: 2.43rem;
  }
}

.botSelected {
  background: #dfe4ff;
  color: #2a79ef;
  pointer-events: none !important;

  &:hover {
    color: #2a79ef;
    background: #dfe4ff;
  }
}

.botItem {
  display: block;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  position: relative;
}

.isProFeature {
  color: #b9b9b9;
  pointer-events: none;
}

.sidebarChildrenItem {
  // height: 3rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 21px;
  color: #4e5d7b;
}
