@import "./react-h5-audio-player";
@import "./animate-css";
@import "./whatsapp-app";
@import "./help-center.scss";

@import "~react-phone-number-input/style.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~react-virtualized/styles.css";
@import "~@creativebulma/bulma-divider/src/sass/index.sass";
@import "~@creativebulma/bulma-badge/src/sass/index.sass";
@import "~@creativebulma/bulma-tagsinput/src/sass/index";
@import "~bulma-pricingtable/dist/css/bulma-pricingtable.sass";
@import "~bulma-switch/src/sass/index.sass";
@import "~bulma-steps/src/sass/index.sass";
@import "~tributejs/tribute.css";
@import "~pure-react-carousel/dist/react-carousel.es.css";
@import "~react-datepicker/dist/react-datepicker.min.css";
@import "~react-phone-number-input/style.css";
@import "./custom-tags-input.scss";
@import "~react-quill/dist/quill.snow.css";