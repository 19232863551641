.container {
  width: 325px;
  // overflow-y: auto;

  @media only screen and (max-width: 769px) {
    width: 100%;
    border-right: none;
  }
}

.header {
  min-height: 48px;
}

.actionsStrip {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.myChatsContainer {
  max-height: 40vh;
  min-height: 25vh;
  overflow-y: auto;
}

.channelButton {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 30px;
  // border: 0;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(247, 244, 255, 0.8);

  span {
    width: 1rem !important;
    height: 1rem !important;
  }

  &:hover {
    border: 0;
    box-shadow: 0px 2px 5px rgba(247, 244, 255, 0.8) !important;
  }

  &:focus {
    border: 0;
    box-shadow: 0px 2px 5px rgba(247, 244, 255, 0.8) !important;
  }
}

.top {
  z-index: 999;
}

.header {
  height: 5.56rem;
  min-height: 48px;
  max-height: 5.56rem;
}

.channelWrapper {
  box-shadow: 0px 4px 10px rgba(193, 192, 192, 0.25);
  border-radius: 10px;
}

.channelName {
  cursor: pointer;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #404040;
}

.channelActive {
  background: #f6f5ff !important;
}

.searchContainer {
  box-shadow: 0px 2px 5px rgba(243, 243, 243, 0.8);
  border-radius: 56px;
  position: relative;
}

.searchIcon {
  height: 2.5rem !important;
}

.searchInput {
  box-shadow: none;
  border: none;
  border-radius: 56px 0 0 56px;
  padding-left: 1.3rem;

  &:focus {
    border: 0;
    box-shadow: none;
  }
}

.datePickerBtn {
  background: transparent;
  border: 0;
  border-left: 1px solid #f3f6fb;
  margin-right: 4px;

  &:hover {
    border-left: 1px solid #f3f6fb;
  }

  &:active {
    border-left: 1px solid #f3f6fb;
  }

  &:focus {
    border-left: 1px solid #f3f6fb;
    box-shadow: none !important;
  }
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.h90 {
  height: 90%;

  // @media only screen and (max-width: 769px) {
  //   height: 100%;
  // }
}

.datePickerContainer {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(193, 192, 192, 0.25);
  border-radius: 4px;
  padding: 1rem;
}

.datePickerInput {
  width: 100px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #fbfbfb;
  border-radius: 23px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #5b626a;
  border: 1px solid #fbfbfb;
  border-radius: 23px;
}

.datePickerInputLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #2a79ef;
}

.labelButton {
  background: #ffffff;
  border: none;
  box-shadow: 0px 2px 5px rgba(243, 243, 243, 0.8);
  border-radius: 56px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #585a5e;
  padding: 18px 25px;

  &:focus {
    border: 0;
    box-shadow: 0px 2px 5px rgba(243, 243, 243, 0.8) !important;
  }

  @media (max-width: 640px) {
    width: 2.5rem;
    height: 2.5rem;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
  }
}

.labelButtonOnly {
  background: #ffffff;
  border: none;
  box-shadow: 0px 2px 5px rgba(243, 243, 243, 0.8);
  border-radius: 56px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #585a5e;
  padding: 18px 25px;

  &:focus {
    border: 0;
    box-shadow: 0px 2px 5px rgba(243, 243, 243, 0.8) !important;
  }
}

.labelBtnText {
  @media (max-width: 640px) {
    display: none;
  }
}

.labelWrapper {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(193, 192, 192, 0.25);
  border-radius: 10px;
  padding: 1rem;
  width: 200px;
  max-height: 400px;
  overflow: scroll;
}

.labelInput {
  width: 4rem;
  border: none;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  /* identical to box height */

  color: #aaaaaa;

  &:focus {
    border: none;
    outline: none;
  }
}

.labelDiv {
  background: #fbfbfb;
  border-radius: 23px;
  margin: 0.4rem 0;
  padding: 0 0.2rem 0px 1rem;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #4e5d7b;
}

.selectTab {
  border-radius: 6px;
  padding: 4px 10px;
  margin: 0 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: #7e808a;
  cursor: pointer;
}

.selectTabActive {
  background: #dfe4ff;
  color: #2a79ef;
}

.selectNavTab {
  border-radius: 6px;
  padding: 8px 10px;
  margin: 0.4rem 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: #7e808a;
  cursor: pointer;
}

.selectNavTab:hover {
  background: #dfe4ff !important;
  color: #2a79ef;
}

.selectNavTabActive {
  background: #dfe4ff;
  color: #2a79ef;
}

.selectInput {
  width: 100%;

  select {
    width: 100%;
  }
}

.status {
  border-radius: 50%;
  background: rgb(39, 174, 96);
  height: 0.8rem;
  width: 0.8rem;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid white;
}

.select {
  width: 100%;

  select {
    width: 100%;
  }
}
