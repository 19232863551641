.imageModal {
  img {
    max-height: 100%;
    max-width: 100%;
  }

  :global {
    .modal-content {
      width: unset;
      max-height: unset;
    }
  }
}
